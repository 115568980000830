import "../modules/project-gallery";
const initBlock = async function( block ) {
	const slider = block.find( ".js-featured-projects-slider" )[0];

	if ( !slider ) {
		return;
	}

	const { Swiper } = await import( "swiper" );
	const { Navigation, Pagination } = await import( "swiper/modules" );
	await import( "swiper/css/bundle" );

	new Swiper( slider, {
		slidesPerView: 1,
		centerInsufficientSlides: true,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			}
		},
		modules: [Navigation, Pagination],
		navigation: {
			prevEl: block.find( "[data-prev-el]" )[0],
			nextEl: block.find( "[data-next-el]" )[0],
		},
		pagination: {
			el: block.find( "[data-pagination-el]" )[0],
			type: "fraction"
		}
	} );

	block.find( ".js-open-project-gallery" ).on( "click", function( event ) {
		block.find( "#project-gallery" ).modal( "show", event );
	} );
};

themeUtils.loadBlock( initBlock, "featured-projects", ".section-featured-projects" );
