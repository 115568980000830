import Swiper from "swiper";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
$( function() {
	$( document ).ready( async function() {
		if ( $( "#project-gallery" ).length ) {
			const galleryPopup = $( "#project-gallery" );
			const gallery = galleryPopup.find( ".js-project-gallery" );
			const swiper = new Swiper( gallery[0], {
				slidesPerView: 1,
				spaceBetween: 10,
				autoHeight: true,
				simulateTouch: false,
				effect: "fade",
				fadeEffect: {
					crossFade: true,
				},
				observer: true,
				normalizeSlideIndex: false,
				modules: [Navigation, Pagination, EffectFade],
				navigation: {
					prevEl: galleryPopup.find( "[data-prev-el]" )[0],
					nextEl: galleryPopup.find( "[data-next-el]" )[0],
				},
				pagination: {
					el: galleryPopup.find( "[data-pagination-el]" )[0],
					type: "fraction"
				}
			} );

			const collapsedBlocks = galleryPopup.find( ".js-collapse" );

			const resizeObserver = new ResizeObserver( entries => {
				for ( let entry of entries ) {
					const currentHeight = entry.target.clientHeight;
					const previousHeight = entry.target.dataset.previousHeight || 0;

					if ( currentHeight !== previousHeight ) {
						swiper.updateAutoHeight();
						entry.target.dataset.previousHeight = currentHeight;
					}
				}
			} );

			collapsedBlocks.each( ( index, collapsedBlock ) => {
				resizeObserver.observe( collapsedBlock );
			} );

			galleryPopup.on( "show.bs.modal", function( event ) {
				let projectIndex;

				if ( typeof event.relatedTarget === "undefined" ) {
					projectIndex = $( ".js-open-project-gallery.active" ).data( "index" );
					$( ".js-open-project-gallery.active" ).removeClass( "active" );
				} else {
					projectIndex = $( $( event.relatedTarget.target )[0] ).data( "index" );
				}

				swiper.slideTo( projectIndex );
			} );
		}
	} );
} );
